<template>
  <div class=container>
    <div class="row">
      <div class="col-md-12">
        <div class="home-content">
        <h1 class="mt-3">Long Read Assembly Pipeline</h1>
          <div class="text-left">
            <p>The RTDBox long read pipeline includes five stages to construct a High Resolution Transcriptome from Single Molecule (HTSM) sequencing data.</p>
            <h6>Input Files</h6>
            <p>
            <ol>
              <li>(Mandatory) Reference genome fasta file.</li>
              <li>(Mandatory) Iso-seq subread sequence bam files of one or multiple samples.</li>
              <li>(Optional) RTD bed or gtf file to integrate to HTSM assembly. The RTD must be assembled based on the same reference genome.</li>
              <li>(Optional) A tab delimited file of splice junction database. The first four columns of data base must be chromosome names match to the reference genome, start coordinate, end coordinate and strand of the splice junction.</li>
            </ol>
            </p>

            <h6>Metatable</h6>
            <p>A three column metatable CSV file (comma delimited) is required to list the input file information.
            <ol>
              <li>The first column is the “Category” to distinguish the input file data types. Please use the same content as in the example. The program will search “genome” to get the genome file, “sjdatabase” to get the prior splice junction information, “rtd” to get the RTD to be integrated to the HTSM assembly and “read” to get the reads files to assembly RTD of HTSM.</li>
              <li>The second column is the “Tag” to distinguish the multiple files in the same category. The content is flexible. The “Tag” of “rtd” row is used as prefix to label the transcript ids from the provided rtd. The “Tag” of “read” category is important. please use simplified sample ids to tag the read files from different samples. The program will use these tags as prefix to label the output of parallel analysis of these samples.</li>
              <li>The third column list the corresponding files for the analysis.</li>
            </ol>
            </p>
            <p>Example of a metatable:
              <!-- Indentation and layout of below text is replicated on screen, DO NOT CHANGE! -->
              <pre><samp>
    genome,reference,morex_genome.fasta
    sjdatabase,junction,sjdatabase.tab
    rtd,rtd2merge,morex_short_reads_rtd.gtf
    read,s1,morex_sample1.bam
    read,s2,morex_sample2.bam
    read,s3,morex_sample3.bam
              </samp></pre>
            </p>
          </div>
          <b-card-group deck>
            <b-card title="Stage 1">
              <b-card-text>Iso-Seq Subreads Pre-Processing</b-card-text>
              <template v-slot:footer>
                <b-button @click="$router.push('preprocessing')" variant="primary">Start</b-button>
              </template>
            </b-card>

            <b-card title="Stage 2">
              <b-card-text>Transcript Assembly</b-card-text>
              <template v-slot:footer>
                <b-button @click="$router.push('lrAssembly')" variant="primary">Start</b-button>
              </template>
            </b-card>

            <b-card title="Stage 3">
              <b-card-text>Transcript Filter and Quality Control</b-card-text>
              <template v-slot:footer>
                <b-button @click="$router.push('filter')" variant="primary">Start</b-button>
              </template>
            </b-card>

            <b-card title="Stage 4">
              <b-card-text>Integrate Additional Source (e.g. Short Read RTD; Optional)</b-card-text>
              <template v-slot:footer>
                <b-button @click="$router.push('additionalSource')" variant="primary">Start</b-button>
              </template>
            </b-card>

            <b-card title="Stage 5">
              <b-card-text>Functional Analysis</b-card-text>
              <template v-slot:footer>
                <b-button @click="$router.push('analysis')" variant="primary">Start</b-button>
              </template>
            </b-card>
          </b-card-group>
          
          <b-row class="row align-items-center justify-content-center" style="margin: 10px 5px">
            <b-button @click="$router.push('home')" variant="primary">Track Exisiting Jobs</b-button>
          </b-row>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'longread-home'
}
</script>

<style scoped>
</style>
